import React from "react";
import App from "next/app";
import {ThemeProvider, CSSReset, Flex } from "@chakra-ui/core";
import { appWithTranslation } from "next-i18next";

import ErrorScreen from "./_error";
//import InstagramBlockScreen from "./_instagram-block"; // Nuevo componente para bloqueo

import reporter from "~/reporting";
import MaintainanceScreen from "./_maintainance";

process.on("unhandledRejection", (error: Error) => {
  reporter.exception(error, {origin: "store | server | unhandledRejection"});
});

process.on("uncaughtException", (error: Error) => {
  reporter.exception(error, {origin: "store | server | uncaughtException"});
});

class Pency extends App {
  state = {
    isMaintenance: process.env.NEXT_PUBLIC_MAINTAINANCE === "MAINTAINANCE",
    isBlocked: false
  }

  componentDidMount() {
   // const referrer = document.referrer.toLowerCase();
    const userAgent = window.navigator.userAgent.toLowerCase();
    
    const isFromInstagramApp = userAgent.includes('instagram');
    const isBlocked = isFromInstagramApp
    
    this.setState({ isBlocked });
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    reporter.exception(error, {extras: errorInfo, origin: "store | client"});
    if (super.componentDidCatch) {
      super.componentDidCatch(error, errorInfo);
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    const {statusCode: error, statusText} = pageProps;
    const { isMaintenance } = this.state;

    // Si viene de Instagram o Linktree, mostrar pantalla de bloqueo
    // if (isBlocked) {
    //   return (
    //     <ThemeProvider>
    //       <InstagramBlockScreen />
    //     </ThemeProvider>
    //   );
    // }

    return (
      <ThemeProvider>
        <CSSReset />
        {error ? (
          <ErrorScreen statusCode={error} statusText={statusText} />
        ) : (
          <>
          {isMaintenance ? (
            <MaintainanceScreen message={'Pagina en mantenimiento'} heading={'Pency'} />
          ) : (
            <Flex direction="column" height="100%">
              <Component {...pageProps} />
            </Flex>
          )}
          </>
        )}
      </ThemeProvider>
    );
  }
}

export default appWithTranslation(Pency as any);
